<template>
    <footer>
        <div class="container p-0">
            <div class="d-flex justify-content-between align-items-center top p-4">
                <div class="col-lg-5 col-xl-4">
                    <a href="https://lineelecco.it/">
                        <img src="../../../assets/img/tenants/188762d1-41c4-4fcc-88db-b0508d2e67ba.svg" alt="Trasporto Pubblico e Locale - Linee Lecco" class="w-25">
                    </a>
                </div>
                <div class="col-lg-5 col-xl-4 d-flex justify-content-between align-items-center">
                    <a href="https://lineelecco.it/trasporto/">
                        <img src="../../../assets/lineelecco-ico/trasporto.png" class="gray">
                    </a>
                    <a href="https://lineelecco.it/trasporto/">
                        <img src="../../../assets/lineelecco-ico/noleggio.png" class="gray">
                    </a>
                    <a href="https://lineelecco.it/trasporto/">
                        <img src="../../../assets/lineelecco-ico/parcheggi.png" class="gray">
                    </a>
                    <a href="https://lineelecco.it/trasporto/">
                        <img src="../../../assets/lineelecco-ico/officina.png" class="gray">
                    </a>
                </div>
            </div>
            <div class="d-flex justify-content-between align-items-center mt-5 p-4">
                <div class="col-4">
                    <strong>Linee Lecco S.p.a.</strong>
                    <p class="my-4">L’Ufficio Informazioni è sempre a disposizione, per fornire risposte alle vostre domande. P.IVA 02320500131 - C.F. 92004770134</p>
                    <a href="https://lineelecco.it/linee-lecco/cookie-policy/" class="my-a mx-4 text-decoration-underline">COOKIE</a>
                    <a href="https://lineelecco.it/linee-lecco/privacy-policy/" class="my-a text-decoration-underline">PRIVACY</a>
                </div>
                <div class="col-4 px-5">
                    <div class="d-flex align-items-center">
                        <i class="fa-solid fa-location-dot"></i>
                        <span class="mx-3">P.zza Bione, <br> 15 23900 Lecco (LC) | Italia</span>
                    </div>
                    <div class="d-flex align-items-center my-2">
                        <i class="fa-solid fa-phone"></i>
                        <span class="mx-3">+39 0341.359911</span>
                    </div>
                    <div class="d-flex align-items-center">
                        <i class="fa-solid fa-envelope-open"></i>
                        <span class="mx-3">lineelecco@pec.it</span>
                    </div>
                </div>
                <div class="col-4">
                    <span>Calcola il tuo percorso</span>
                    <a href="https://lineelecco.it/extended2/" class="col-4 p-2 my-2 my-a d-flex justify-content-center align-items-center bg-tenant-primary cursor-pointer w-100 text-white">
                        <i class="fa-regular fa-circle-play me-2"></i>
                        <strong class="text-white">TRANSFERO</strong>
                    </a>
                </div>
            </div>
        </div>
        <div class="linked-bar d-flex justify-content-around align-items-center text-white p-1 flex-wrap">
            <div class="col-12 col-md-4 px-2 d-flex justify-content-around align-items-center">
                <span>Seguici!</span>
                <a class="my-a text-white" href="https://www.facebook.com/lineelecco" target="_blank">
                    <i class="fa-brands fa-facebook-f"></i>
                </a>
                <a class="my-a text-white" href="https://www.instagram.com/lineelecco" target="_blank">
                    <i class="fa-brands fa-instagram"></i>
                </a>
                <a class="my-a text-white" href="https://www.youtube.com/channel/UCaU8hbvymOlV7FK9udwlBaA" target="_blank">
                    <i class="fa-brands fa-youtube"></i>
                </a>
            </div>
            <div class="col-12 col-md-4  d-flex justify-content-end px-2">
                <span>
                    &copy; 2021 Linee Lecco. All Rights Reserved. | <a class="my-a text-white text-decoration-underline" href="https://www.digitaladrenalin.it/">Digital Adrenalin</a>
                </span>
                
            </div>
        </div>
    </footer>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
    footer {
        background-color: #e3e3e3;

        .top {
            border-bottom: 1px solid #b8b8b8;
            .gray {
                transition: 0.3s;
                filter: grayscale(100%);
                opacity: 0.8;
    
                &:hover {
                    filter: grayscale(0%);
                    opacity: 1;
                }
            }
        }

        strong {
            font-size: 18px;
            color: #2a4f9d;
        }

        .bg-tenant-primary {
            transition: 0.3s;

            &:hover {
                background-color: #0787c9;
            }
        }

        .linked-bar {
            background-color: #2a4f9d;
        }

    }

    @media screen and (max-width: 992px) {
        footer {

            .container {
                display: none;
            }
        }
    }
</style>