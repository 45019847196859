<template>
    <div class="linked-bar justify-content-between align-items-center p-1">
        <div class="col-1 px-2 d-flex justify-content-between align-items-center">
            <a class="my-a" href="https://www.facebook.com/lineelecco" target="_blank">
                <i class="fa-brands fa-facebook-f"></i>
            </a>
            <a class="my-a" href="https://www.instagram.com/lineelecco" target="_blank">
                <i class="fa-brands fa-instagram"></i>
            </a>
            <a class="my-a" href="https://www.youtube.com/channel/UCaU8hbvymOlV7FK9udwlBaA" target="_blank">
                <i class="fa-brands fa-youtube"></i>
            </a>
        </div>
        <div class="col-4 d-flex justify-content-end px-2">
            <a class="my-a" href="https://lineelecco.it/linee-lecco/trasparenza/bandi-di-gara/">
                <strong>Bandi di gara</strong>
            </a>
            <a class="my-a mx-4 a-middle" href="https://lineelecco.it/linee-lecco/trasparenza/bandi-di-concorso/">
                <strong>Lavora con noi</strong>
            </a>
            <a class="my-a" href="https://lineelecco.it/linee-lecco/documenti/">
                <strong>Documenti</strong>
            </a>
        </div>
    </div>
    <header>
        <div class="col-6 col-sm-4 col-lg-1 p-2">
            <a href="https://lineelecco.it/">
                <img src="../../../assets/img/tenants/188762d1-41c4-4fcc-88db-b0508d2e67ba.svg" alt="Trasporto Pubblico e Locale - Linee Lecco" class="w-100">
            </a>
        </div>
        <nav class="col-10">
            
            <a class="col-2 p-2 d-flex flex-column justify-content-end my-a" href="https://lineelecco.it/linee-lecco/">
                <strong>. Linee Lecco</strong>
            </a>
            
            <a class="col-2 p-2 d-flex flex-column justify-content-end my-a" href="https://lineelecco.it/trasporto/">
                <strong>. Trasporto</strong>
            </a>
            
            <a class="col-2 p-2 d-flex flex-column justify-content-end my-a" href="https://lineelecco.it/noleggio/">
                <strong>. Noleggio</strong>
            </a>
            
            <a class="col-2 p-2 d-flex flex-column justify-content-end my-a" href="https://lineelecco.it/parcheggi/">
                <strong>. Parcheggi</strong>
            </a>
            
            <a class="col-2 p-2 d-flex flex-column justify-content-end my-a" href="https://lineelecco.it/officina/">
                <strong>. Officina</strong>
            </a>
            <a class="col-2 p-2 d-flex flex-column justify-content-end border-end-0 my-a" href="https://lineelecco.it/contatti/">
                <strong>. Contatti</strong>
            </a>
        </nav>
    </header>
    <div class="nav-bar justify-content-between align-items-center bg-tenant-primary mb-3">
        <div class="col-8 d-flex justify-content-around align-items-center">
            <a href="https://lineelecco.it/trasporto/tariffe/" class="my-a dropdown-toggle" data-bs-toggle="dropdown">Orari</a>
            <ul class="dropdown-menu rounded-0 p-0">
                <li>
                    <a class="dropdown-item" href="https://lineelecco.it/trasporto/orari-invernali/">Invernali</a>
                </li>
                <li>
                    <a class="dropdown-item" href="https://lineelecco.it/trasporto/orari-estivi/">Estivi</a>
                </li>
            </ul>
            <a href="https://lineelecco.it/trasporto/tariffe/" class="my-a">Tariffe</a>
            <a href="https://lineelecco.it/trasporto/abbonamenti/" class="my-a">Abbonamenti</a>
            <a href="https://lineelecco.it/trasporto/navette/" class="my-a">Navette</a>
            <a href="https://lineelecco.it/trasporto/piedibus/" class="my-a">Piedibus</a>
            <a href="https://lineelecco.it/trasporto/scioperi/" class="my-a">Scioperi</a>
            <a href="https://lineelecco.it/trasporto/news/" class="my-a">News</a>
            <a href="https://lineelecco.it/trasporto/contatti/" class="my-a">Contatti</a>
        </div>
        <a href="https://lineelecco.it/extended2/" class="col-4 p-2 my-a d-flex justify-content-center align-items-center bg-tenant-secondary cursor-pointer">
            <i class="fa-regular fa-circle-play me-2"></i>
            <strong>TRANSFERO</strong>
        </a>
    </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>

    .linked-bar {
        display: flex;
        font-size: 14px;
        background-color: #cecece;
        
        .a-middle {
            padding: 0 10px;
            border-left: 2px solid #737373;
            border-right: 2px solid #737373;
        }


        i,
        strong {
            color: #737373;
            transition: 0.3s;

            &:hover {
                color: #2a4f9d;
            }
        }
    }

    header {
        display: flex;
        justify-content: space-between;
        background-color: #e3e3e3;

        nav {
            display: flex;

            a {
                border-right: 1px solid #b8b8b8;
                transition: 0.3s;
                cursor: pointer;

                &:hover {
                    border-bottom: 5px solid #2a4f9d;
                    color: #2a4f9d;
                }
                
            }
        }
    }

    .nav-bar {
        display: flex;
        box-shadow: 0 5px 10px 0 black;
        
        a {
            transition: 0.3s;
            color: #d7d9de;

            &:hover {
                color: white;
            }
        }

        .bg-tenant-secondary {
            transition: 0.3s;
            color: #d7d9de;
            
            &:hover {
                background-color: #1d9de0;

                i,
                strong {
                    color: white;
                } 
            }
        }

        .dropdown-menu {
            border-top: 5px solid #0787c9;
            background-color: #e3e3e3;

            .dropdown-item {
                transition: 0.3s;
                color: black;

                &:hover {
                    color: #1d9de0;
                }
            }
        }
    }

    @media screen and (max-width: 992px) {
        .linked-bar,
        .nav-bar {
            display: none;
        }

        header {
            justify-content: center;
            background-color: transparent;

            nav {
                display: none;
            }
        }
    }
</style>