<template>
    <div class="backdrop" :class="this.$route.name == 'EmailService' ? 'small' : ''">
        <LineeLeccoHeader/>
        <div class="container p-0">
            <div class="d-flex flex-wrap animated">
                <div class="col-lg-3 col-xl-4 col-offset-sx">       
                    <h1>
                        . Trasporto <br>
                        Pubblico <br>
                        Locale
                    </h1>
                </div>
                <div class="col-12 col-sm-10 offset-sm-1 col-lg-6 offset-lg-0 col-xl-4">
                    <Login v-if="this.$route.name == 'Login'"/>
                    <Sign v-if="this.$route.name == 'Sign'"/>
                    <EmailService v-if="this.$route.name == 'EmailService'"/>
                    <ResetPassword v-if="this.$route.name == 'ResetPassword'"/>
                </div>
            </div>
        </div>
        <LineeLeccoFooter/>
    </div>
</template>

<script>
import LineeLeccoHeader from '../Header.vue'
import Login from '@/components/Login.vue'
import Sign from '@/components/Sign.vue'
import EmailService from '@/components/EmailService.vue'
import ResetPassword from '@/components/ResetPassword.vue'
import LineeLeccoFooter from '../Footer.vue'
export default {
    name: 'LineeLeccoAuth',
    components: {
        LineeLeccoHeader,
        Login,
        Sign,
        EmailService,
        ResetPassword,
        LineeLeccoFooter
    }
}
</script>

<style lang="scss" scoped>
    .backdrop {
        background-color: #2a4f9d;

        &.small {
            height: 100vh;
        }
    }

    .animated {
        position: relative;
        animation: show 1s linear;
    }
    @keyframes show {
        from {
            left: -200px;
            opacity: 0;
            transform: skewX(-20deg);
        }
        to {
            left: 0;
            opacity: 1;
            transform: skewX(0deg);
        }
    }

    .col-offset-sx {
        background-image: url('../../../../assets/img/tenants/LineeLC.png');
        background-size: contain;
        background-repeat: no-repeat;
        padding: 40px;

        h1 {
            font-size: 65px;
            color: #2a4f9d;
            text-shadow: 2px 0 0 #fff, -2px 0 0 #fff, 0 2px 0 #fff, 0 -2px 0 #fff, 1px 1px #fff, -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff;
        }
    }

    @media screen and (max-width: 992px) {
        .col-offset-sx {
            display: none;
        }

        .backdrop {
            background-image: url('../../../../assets/img/tenants/LineeLC.png');
            background-size: cover;
            background-position: left;
        }
    }
    @media screen and (max-width: 1199px) {
        .col-offset-sx h1 {
            font-size: 34px;
        }
    }
</style>